import { lazy, Suspense, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ContactUs } from "../../pages/ContactUs/ContactUs";
import CircularProgressIndicator from "../../UI/CircularProgressIndicator/CircularProgressIndicator";
import { Footer } from "../Footer/Footer";
import { MainHeader } from "../MainHeader/MainHeader";
import styles from "./Layout.module.scss";
import { Modal } from "react-bootstrap";

const AboutUs = lazy(() => import("../../pages/AboutUs/AboutUs"));
const OurWorks = lazy(() => import("../../pages/OurWorksNew/OurWorks"));
const Projects = lazy(() => import("../../pages/Projects/Projects"));
const WorkingAreas = lazy(() =>
  import("../../pages/WorkingAreas/WorkingAreas")
);

export const Layout = () => {
  const [isContactUsOpen, setIsContactUsOpen] = useState(false);

  const openModal = () => {
    setIsContactUsOpen(true);
  };

  const closeModal = () => {
    setIsContactUsOpen(false);
  };

  return (
    <>
      <Modal show={isContactUsOpen} onHide={closeModal}>
        <ContactUs onClick={closeModal} />
      </Modal>
      <MainHeader />
      <main className={styles.main}>
        <Suspense fallback={<CircularProgressIndicator />}>
          <Switch>
            <Route path="/" exact>
              <Redirect to="/about-us" />
            </Route>
            <Route path="/about-us">
              <AboutUs />
            </Route>
            <Route path="/working-areas">
              <WorkingAreas />
            </Route>
            <Route path="/projects">
              <Projects />
            </Route>
            <Route path="/our-works">
              <OurWorks />
            </Route>
          </Switch>
        </Suspense>
      </main>
      <Footer onClick={openModal} />
    </>
  );
};
