import PropTypes from "prop-types";
import React from "react";
import styles from "./Footer.module.scss";

export const Footer = (props) => {
  return (
    <footer className={styles.footer} onClick={props.onClick}>
      <h2>Contact Us</h2>
    </footer>
  );
};

Footer.propTypes = {
  onClick: PropTypes.func.isRequired,
};
