import { Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "./MainHeader.scss";

export const MainHeader = () => {
  const activeStyle = {
    background: "#1200FF",
    borderRadius: "5px",
    textDecoration: "none",
    color: "#fff",
    fontWeight: "bold",
    fontSize: "17px",
  };

  const inactiveStyle = {
    textDecoration: "none",
    color: "#000",
    fontSize: "17px",
  };

  const navbarBrandClassName = "navbarBrandLight";
  const navbarOuterClassName = "navBarOuterLight";

  return (
    <header>
      <div className={navbarOuterClassName}>
        <Navbar collapseOnSelect expand="md" className="navBar" variant="light">
          <Navbar.Brand href="/" className={navbarBrandClassName}>
            <img src="/images/full_logo_1.png" alt="Stac Consultants" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" className="" />
          <Navbar.Collapse id="navbarScroll" className={`navbarMainPart`}>
            <Nav>
              <Nav.Link
                as={NavLink}
                to="/about-us"
                exact
                style={(isActive) => (isActive ? activeStyle : inactiveStyle)}
              >
                About Us
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to="/working-areas"
                style={(isActive) => (isActive ? activeStyle : inactiveStyle)}
              >
                Working Areas
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to="/projects"
                style={(isActive) => (isActive ? activeStyle : inactiveStyle)}
              >
                Projects
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to="/our-works"
                style={(isActive) => (isActive ? activeStyle : inactiveStyle)}
              >
                Our Works
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </header>
  );
};
