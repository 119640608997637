import { Modal } from "react-bootstrap";
import styles from "./ContactUs.module.scss";

const data = {
  Name: ["Dr. B.S. Sudharshan"],
  Phone: ["+91 98440 53520", "+91 80 2665 5959"],
  Email: ["bssudharshan@gmail.com"],
  Address: [
    "No 241, 24th Main,",
    "J.P.Nagar, 5th Phase,",
    "Bangalore - 560 078,",
    "India",
  ],
};

const headings = ["Name", "Phone", "Email", "Address"];

export const ContactUs = ({ onClick }) => {
  return (
    <div className={styles.contactUs}>
      <Modal.Header closeButton>
        <Modal.Title>Contact Information</Modal.Title>
      </Modal.Header>
      <section className={styles.content}>
        <table>
          {headings.map((key) => (
            <tr>
              <th>{key}</th>
              <td>
                {data[key].map((item) => (
                  <div>
                    {key === "Email" ? (
                      <a
                        href="mailto:bssudharshan@gmail.com?subject=General+Query"
                        target="__blank"
                      >
                        {item}
                      </a>
                    ) : (
                      item
                    )}
                  </div>
                ))}
              </td>
            </tr>
          ))}
        </table>
      </section>
    </div>
  );
};
